import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Button, Dropdown, Space } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import FileService from "../../../Services/File";

const DownloadButton: React.FC<any> = ({ file = {} }: { file: any }) => {
  file = useSelector((state: any) => state.file || file);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadFile: MenuProps["onClick"] = async (e) => {
    try {
      await setIsDownloading(true);
      if (FileService.isCanDownload(file)) {
        switch (e.key) {
          case "csv":
            await FileService.download(file.id, "csv", file.name);
            break;
          case "xlsx":
            await FileService.download(file.id, "xlsx", file.name);
            break;
          default:
            await FileService.download(file.id, "csv", file.name);
            break;
        }
      }
    } finally {
      await setIsDownloading(false);
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "Download as CSV",
      key: "csv",
    },
    {
      label: "Download as XLSX",
      key: "xlsx",
    },
  ];
  const menuProps = {
    items,
    disabled: !FileService.isCanDownload(file),
    onClick: handleDownloadFile,
  };

  return (
    <Dropdown menu={menuProps}>
      <Button type="primary" loading={isDownloading}>
        <Space>
          Download
          <DownloadOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default DownloadButton;
