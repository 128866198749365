import axios from "axios";

import AuthService from "../../Services/Auth";


const API_URL = process.env.REACT_APP_API_URL + "/file";


const upload = async (file: File): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const headers: any = await AuthService.getHeader();
    headers["Content-Type"] = "multipart/form-data";

    const response = await axios.post(API_URL + "/upload", formData, {
      headers,
    });

    if (response.status != 201) {
      throw new Error("Upload file failed");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

const download = async (fileId: number, fileTag: string, fileName: string = "validated_data"): Promise<any> => {
  fileName = fileName + "." + fileTag;
  let query_params = "to_download=true";
  query_params += "&download_file_tag=" + fileTag;
  try {
    const response = await fetch(
      API_URL + "/" + fileId + "/results" + "?" + query_params
    );
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    if (response.status !== 200) {
      throw new Error("Failed to download file");
    }
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

const deleteFile = async (fileId: number): Promise<void> => {
  try {
    const headers: any = await AuthService.getHeader();
    const response = await axios.delete(API_URL + "/" + fileId, {
      headers: headers,
    });

    if (response.status !== 204) {
      throw new Error("Failed to delete file");
    }
  } catch (error) {
    throw error;
  }
};

const getProcessedData = async (
  fileId: number,
  currentPage: number = 1,
  pageSize: number = 10,
  keyword: string = "",
  typeFilter: string[] = [],
): Promise<any> => {
  try {
    const response = await axios.get(API_URL + "/" + fileId + "/results", {
      params: {
        page: currentPage,
        page_size: pageSize,
        keyword: keyword,
        types: typeFilter.join(","),
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

const getFile = async (fileId: number): Promise<any> => {
  try {
    const response = await axios.get(API_URL + "/" + fileId);
    return response.data
  } catch(error: any) {
    throw error
  }
}

const getFileAnalyticsKeyMetrics = async (fileId: number): Promise<any> => {
  try {
    const response = await axios.get(API_URL + "/" + fileId + "/key-metrics");
    return response.data;
  } catch (error: any) {
    throw error;
  }
}

const listFiles = async (
  currentPage: number,
  pageSize: number,
  keyword: string = "",
  sortedBy: string = "",
): Promise<any> => {
  try {
    let queryParams = `page=${currentPage}&page_size=${pageSize}`;
    if (keyword) {
      queryParams += `&keyword=${keyword}`;
    }
    if (sortedBy) {
      queryParams += `&sorted_by=${sortedBy}`;
    }
    const headers: any = await AuthService.getHeader();
    const response = await axios.get(`${API_URL}/all?${queryParams}`, {
      headers: headers,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const mergeFile = async (fileId: number): Promise<void> => {

}

const isCanDownload = (file: any): boolean => {
  if (!file) return false;
  return ["completed", "wait_to_be_validated", "validated"].includes(file.status);
}

const isProcessing = (file: any): boolean => {
  if (!file) return false;
  return ["uploaded", "processing"].includes(file.status);
}

const isMerged = (file: any): boolean => {
  if (!file) return false;
  return ["validated"].includes(file.status);
}


const FileService = {
  upload,
  download,
  deleteFile,
  getProcessedData,
  getFile,
  getFileAnalyticsKeyMetrics,
  listFiles,
  mergeFile,
  isCanDownload,
  isProcessing,
  isMerged,
};

export default FileService;
