import React from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import { logout } from "../../Slices/authSlice";
import { reset } from "../../Slices/userSlice";
import AuthService from "../../Services/Auth";


const LogoutPage: React.FC = () => {
  const dispatch = useDispatch();
  dispatch(logout());
  dispatch(reset());
  AuthService.logout();

  return (
    <Navigate to="/" />
  )
};

export default LogoutPage;
