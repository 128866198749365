import React from "react";

import { Flex } from "antd";

const AnalyticsContainer: React.FC<any> = ({
  children,
  title="Analytics data",
}: {
  children: any,
  title?: string,
}) => {
  return (
    <Flex className="analytics-container" vertical>
      {children}
    </Flex>
  )
}

export default AnalyticsContainer;