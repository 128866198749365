import { Input } from "antd"
import { SearchOutlined } from "@ant-design/icons"


const SearchInput = ({
    inputProps = {},
}) => {
  return (
    <Input 
      prefix={<SearchOutlined />} 
      {...inputProps}
    />
  )
}

export default SearchInput;