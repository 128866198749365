import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Flex, Layout, Menu, theme } from "antd";
import {
  FileTextOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;

const menuStyle = {
  minHeight: "calc(100vh)",
};

const MenuSider: React.FC = () => {
  const { isLoggedIn } = useSelector((state: any) => state.auth);
  const user = useSelector((state: any) => state.user);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const customerSourceItemChildren = [
    {
      path: "/customers/customer-sources",
      label: <a href="/customers/customer-sources">Overview</a>,
      key: "customer_source_list",
    },
  ]
  const customerSourceItems = {
    label: "Customer Source",
    key: "customer_source",
    icon: <UserOutlined />,
    type: "group",
    children: customerSourceItemChildren,
  };
  const fileItemChildren = isLoggedIn
    ? [
        {
          path: "/files",
          label: <Link to="/files/overview">Overview</Link>,
          key: "files-overview",
        },
        {
          path: "/files/upload",
          label: <Link to="/files/upload">Upload</Link>,
          key: "files-upload",
        },
      ]
    : [
        {
          path: "/files/upload",
          label: <Link to="/files/upload">Upload</Link>,
          key: "files-upload",
        },
      ];
  const fileItems = {
    label: "Data",
    key: "file",
    icon: <FileTextOutlined />,
    type: "group",
    children: fileItemChildren,
  };
  
  let menuItems = [
    fileItems,
  ];
  if (user.isSuperuser) {
    menuItems = [
      customerSourceItems, 
      fileItems,
    ];
  }


  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      width={220}
      style={{
        background: colorBgContainer,
      }}
    >
      <Flex className="logo" justify="flex-start" align="center" style={{ marginLeft: "15px"}}>
        <h2>DataValidator</h2>
      </Flex>
      <Menu
        theme="light"
        style={menuStyle}
        mode="inline"
        defaultSelectedKeys={["files-upload"]}
        selectedKeys={[
          `${window.location.pathname.split("/")[1]}-${
            window.location.pathname.split("/")[2]
          }`,
        ]}
        items={menuItems}
      />
    </Sider>
  );
};

export default MenuSider;
