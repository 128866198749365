import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import {
  MainLayout,
  MultiContentLayout,
} from './Layouts';
import LoginPage from './Pages/Auth/LoginPage';
import LogoutPage from './Pages/Auth/LogoutPage';
import RegisterPage from './Pages/Auth/RegisterPage';
import { 
  FileDetailPage,
  FileOverviewPage,
  FileUploadPage,
  FileMergePage,
} from './Pages/File';
import { CustomerSourcesByPhonePage } from './Pages/Customer';
import { ProfileDetailPage, ProfileEditPage } from './Pages/User';
import { UserActionHistoriesListing } from './Pages/History';


const Routers: React.FC = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<FileUploadPage />} />
        </Route>
        <Route path="/auth" element={<MainLayout />}>
          <Route index element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="logout" element={<LogoutPage />} />
        </Route>
        <Route path="/customers" element={<MainLayout />}>
          <Route path="customer-sources">
            <Route index element={<CustomerSourcesByPhonePage />} />
            <Route path="listing" element={<CustomerSourcesByPhonePage />} />
          </Route>
        </Route>
        <Route path="/files" element={<MainLayout />}>
          <Route index element={<FileOverviewPage />} />
          <Route path="overview" element={<FileOverviewPage />} />
          <Route path="detail/:fileId" element={<FileDetailPage />} />
          <Route path="upload" element={<FileUploadPage />} />
        </Route>
        <Route path="/files" element={<MultiContentLayout />}>
          <Route path="merge/:fileId" element={<FileMergePage />} />
        </Route>
        <Route path="/user" element={<MainLayout />}>
          <Route index element={<ProfileDetailPage />} />
          <Route path="me" element={<ProfileDetailPage />} />
          <Route path="update" element={<ProfileEditPage />} />
        </Route>
        <Route path="/history" element={<MainLayout />}>
          <Route index element={<UserActionHistoriesListing />} />
          <Route path="action-histories" element={<UserActionHistoriesListing />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;