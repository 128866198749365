import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Flex, message } from "antd";

import {
  AnalyticsContainer,
  AnalyticsKeyMetricsTable,
} from "../../../Components/Analytics";
import { DownloadButton, FileDataTable } from "../../../Components/File";
import {
  SearchInput,
  ToolsBar,
  TypeSelector,
} from "../../../Components/Common";
import FileService from "../../../Services/File";
import { setFile } from "../../../Slices/fileSlice";

const FileDetailPage: React.FC<any> = () => {
  const { fileId } = useParams();
  const { file } = useSelector((state: any) => state.file);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const [typesFilter, setTypesFilter] = useState([] as string[]);
  const [keyMetrics, setKeyMetrics] = useState({
    unique: 0,
    empty: 0,
    invalid: 0,
    duplicated_in_file: 0,
    duplicated_in_database: 0,
  });

  const getFileDetail = async (fileId: number) => {
    try {
      const response: any = await FileService.getFile(fileId);
      dispatch(
        setFile({
          id: response.id,
          name: response.name,
          tag: response.tag,
          status: response.status,
        })
      );
    } catch (error: any) {
      message.error(error.response.data.detail);
    }
  };

  const hanldeGetFileKeyMetrics = async (fileId: number) => {
    try {
      const response: any = await FileService.getFileAnalyticsKeyMetrics(
        Number(fileId)
      );
      setKeyMetrics(response);
    } catch (error: any) {
      message.error(error.response.data.detail);
    }
  };

  useEffect(() => {
    getFileDetail(Number(fileId));
    hanldeGetFileKeyMetrics(Number(fileId));
  }, [fileId]);

  return (
    <div className="file-detail-page">
      <Flex gap="middle" vertical>
        <AnalyticsContainer
          children={<AnalyticsKeyMetricsTable keyMetrics={keyMetrics} />}
        />
        <ToolsBar
          leftChildren={
            <>
              <SearchInput
                inputProps={{
                  placeholder: "Search by table fields",
                  style: { width: "40%" },
                  onChange: (e: any) => {
                    setSearchText(e.target.value);
                  },
                }}
              />
              <TypeSelector
                style={{
                  width: "40%",
                }}
                onChange={(value: string[]) => {
                  setTypesFilter(value);
                }}
              />
            </>
          }
          rightChildren={<DownloadButton file={file} />}
        />
        <FileDataTable
          scrollHeight={480}
          searchText={searchText}
          typesFilter={typesFilter}
        />
      </Flex>
    </div>
  );
};

export default FileDetailPage;
