import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Button, message, Space } from "antd";
import { PullRequestOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import FileService from "../../../Services/File";

const MergeButton: React.FC<any> = ({ file = {} }: { file: any }) => {
  file = useSelector((state: any) => state.file || file);
  
  const handleMergeFile = () => {
    message.info("Merging file...");
  }

  return (
    <Button type="primary" onClick={handleMergeFile}>
      <Space>
        Merge
        <PullRequestOutlined />
      </Space>
    </Button>
  );
};

export default MergeButton;
