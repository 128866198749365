import { createSlice } from "@reduxjs/toolkit";

interface UserState {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  isSuperuser: boolean;
  isStaff: boolean;
}

const initialState: UserState = {
  id: 0,
  first_name: "",
  last_name: "",
  email: "",
  username: "",
  isSuperuser: false,
  isStaff: false,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser(state, action) {
      state.id = action.payload.id;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
      state.username = action.payload.username;
      state.email = action.payload.email;
      state.isSuperuser = action.payload.isSuperuser;
      state.isStaff = action.payload.isStaff;
    },
    reset(state) {
      state.id = 0;
      state.first_name = "";
      state.last_name = "";
      state.username = "";
      state.email = "";
      state.isSuperuser = false;
      state.isStaff = false;
    },
  },
});

export const { setUser, reset } = userSlice.actions;
export const selectUser = (state: any) => state.user;
export default userSlice.reducer;
