import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Flex, Layout, message, theme } from "antd";

import { FileDataTable, MergeButton } from "../../../Components/File";
import { ToolsBar } from "../../../Components/Common";
import FileService from "../../../Services/File";
import { setFile } from "../../../Slices/fileSlice";

const { Content } = Layout;

const FileMergePage: React.FC<any> = () => {
  const { fileId } = useParams();
  const file = useSelector((state: any) => state.file);
  const dispatch = useDispatch();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const getFileDetail = async (fileId: number) => {
    try {
      const response: any = await FileService.getFile(fileId);
      dispatch(
        setFile({
          id: response.id,
          name: response.name,
          tag: response.tag,
          status: response.status,
        })
      );
    } catch (error: any) {
      message.error(error.response.data.detail);
    }
  };

  const triggerMergeFile = async (fileId: number) => {
    try {
      await FileService.mergeFile(fileId);
      message.success("Merged file successfully");
    } catch (error: any) {
      message.error("Failed to merge file: " + error.message);
    }
  
  }

  useEffect(() => {
    getFileDetail(Number(fileId));
  }, [fileId]);

  return (
    <div className="file-merge-page">
      <Content
        className="unique-file-data-table-container"
        style={{
          padding: "15px",
          backgroundColor: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Flex className="unique-file-data-table" gap="small" vertical>
          <ToolsBar
            leftChildren={
              <Flex align="center">
                <h3>Unique data</h3>
              </Flex>
            }
            rightChildren={
              <Flex align="center">
                <MergeButton fileId={fileId} />
              </Flex>
            }
            style={{ marginTop: "0px" }}
          />
          <FileDataTable
            fileId={fileId}
            scrollWidth="0px"
            typesFilter={["unique"]}
            excludeFields={["type", "note", "duplicated_file"]}
          />
        </Flex>
      </Content>

      <Content
        className="empty-file-data-table-container"
        style={{
          padding: "15px",
          marginTop: "20px",
          backgroundColor: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Flex className="empty-file-data-table" gap="small" vertical>
          <ToolsBar
            leftChildren={
              <Flex align="center">
                <h3>Empty data</h3>
              </Flex>
            }
            style={{ marginTop: "0px" }}
          />
          <FileDataTable
            fileId={fileId}
            scrollWidth="0px"
            typesFilter={["empty"]}
            excludeFields={["type", "note", "duplicated_file"]}
          />
        </Flex>
      </Content>

      <Content
        className="invalid-file-data-table-container"
        style={{
          padding: "15px",
          marginTop: "20px",
          backgroundColor: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Flex className="invalid-file-data-table" gap="small" vertical>
          <ToolsBar
            leftChildren={
              <Flex align="center">
                <h3>Invalid data</h3>
              </Flex>
            }
            style={{ marginTop: "0px" }}
          />
          <FileDataTable
            fileId={fileId}
            scrollWidth="0px"
            typesFilter={["invalid"]}
            excludeFields={["type", "note", "duplicated_file"]}
          />
        </Flex>
      </Content>

      <Content
        className="duplicated-file-data-table-container"
        style={{
          padding: "15px",
          marginTop: "20px",
          backgroundColor: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Flex className="duplicated-file-data-table" gap="small" vertical>
          <ToolsBar
            leftChildren={
              <Flex align="center">
                <h3>Duplicated data</h3>
              </Flex>
            }
            style={{ marginTop: "0px" }}
          />
          <FileDataTable
            fileId={fileId}
            scrollWidth="0px"
            typesFilter={["duplicated_in_file", "duplicated_in_database"]}
            excludeFields={["type", "note"]}
          />
        </Flex>
      </Content>
    </div>
  );
};

export default FileMergePage;
