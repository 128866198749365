import { Flex } from "antd";

const ToolsBar = ({
  leftChildren = null,
  rightChildren = null,
  style = {},
}: {
  leftChildren?: any;
  rightChildren?: any;
  style?: any;
}) => {
  if (!leftChildren && rightChildren) {
    return (
      <Flex className="tools-bar" justify="flex-end" gap="small" style={style}>
        {rightChildren}
      </Flex>
    );
  } else if (leftChildren && !rightChildren) {
    return (
      <Flex
        className="tools-bar"
        justify="flex-start"
        gap="small"
        style={style}
      >
        {leftChildren}
      </Flex>
    );
  } else {
    return (
      <Flex className="tools-bar" justify="space-between" style={style}>
        <Flex
          className="tools-bar-left"
          justify="flex-start"
          gap="small"
          style={{ width: "100%" }}
        >
          {leftChildren}
        </Flex>
        <Flex
          className="tools-bar-right"
          justify="flex-end"
          gap="small"
          style={{ width: "100%" }}
        >
          {rightChildren}
        </Flex>
      </Flex>
    );
  }
};

export default ToolsBar;
