import { createSlice } from '@reduxjs/toolkit';


interface FileState {
  id: number | null
  name: string | null
  status: string | null
  tag: string | null
}

const initialState: FileState = {
  id: null,
  name: null,
  status: null,
  tag: null,
}

const fileSlice = createSlice({
  name: 'file',
  initialState: initialState,
  reducers: {
    uploadFile(state, action) {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.tag = action.payload.tag;
      state.status = "uploaded";
    },
    setFile(state, action) {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.tag = action.payload.tag;
      state.status = action.payload.status;
    },
    setStatus(state, action) {
      state.status = action.payload.status;
    },
    reset(state) {
      state.id = null;
      state.name = null;
      state.status = null;
      state.tag = null;
    },
  },
});


export const { uploadFile, setFile, setStatus, reset } = fileSlice.actions;
export const selectFile = (state: any) => state.file;
export default fileSlice.reducer;