import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Flex } from "antd";

import {
  DownloadButton,
  FileDragger,
  FileDataTable,
} from "../../../Components/File";
import {
  SearchInput,
  ToolsBar,
  TypeSelector,
} from "../../../Components/Common";

const FileUploadPage: React.FC = () => {
  const { file } = useSelector((state: any) => state.file);
  const [searchText, setSearchText] = useState("");
  const [typesFilter, setTypesFilter] = useState([] as string[]);

  return (
    <div className="file-upload-page">
      <Flex vertical>
        <FileDragger />
        <ToolsBar
          leftChildren={
            <>
              <SearchInput
                inputProps={{
                  placeholder: "Search by table fields",
                  style: { width: "40%" },
                  onChange: (e: any) => {
                    setSearchText(e.target.value);
                  },
                }}
              />
              <TypeSelector
                style={{
                  width: "40%",
                }}
                onChange={(value: string[]) => {
                  setTypesFilter(value);
                }}
              />
            </>
          }
          rightChildren={<DownloadButton />}
          style={{ marginTop: "20px" }}
        />
        <FileDataTable
          scrollHeight={240}
          file={file}
          searchText={searchText}
          typesFilter={typesFilter}
        />
      </Flex>
    </div>
  );
};

export default FileUploadPage;
