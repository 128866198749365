import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { Flex } from "antd";

import { FilesTable } from "../../../Components/File";
import { SearchInput, ToolsBar } from "../../../Components/Common";

const FileOverviewPage: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const { isLoggedIn } = useSelector((state: any) => state.auth);

  if (!isLoggedIn) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <div className="file-overview-page">
      <Flex vertical>
        <ToolsBar
          leftChildren={
            <SearchInput
              inputProps={{
                placeholder: "Search by table fields",
                onChange: (e: any) => {
                  setSearchText(e.target.value);
                },
                style: { width: "20%" },
              }}
            />
          }
        />
        <FilesTable searchText={searchText} />
      </Flex>
    </div>
  );
};

export default FileOverviewPage;
