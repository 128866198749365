import React, { useEffect, useState } from 'react';
import { Table, Tag, Space, Button } from 'antd';
import HistoryService from '../../../Services/History';


const ActionHistoryTable = () => {
    const [dataSource, setDataSource] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortField, setSortField] = useState('id');
    const [isAscending, setIsAscending] = useState(true);
    const [total, setTotal] = useState(0);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a: any, b: any) => {
                if (sortField === 'id' || sortField === '-id') {
                    setIsAscending(!isAscending);
                }
                if (isAscending) {
                    setSortField('id');
                    return 1;
                }
                setSortField('-id');
                return -1;
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            sorter: (a: any, b: any) => {
                if (sortField === 'action' || sortField === '-action') {
                    setIsAscending(!isAscending);
                }
                if (isAscending) {
                    setSortField('action');
                    return 1;
                }
                setSortField('-action');
                return -1;
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: (a: any, b: any) => {
                if (sortField === 'description' || sortField === '-description') {
                    setIsAscending(!isAscending);
                }
                if (isAscending) {
                    setSortField('description');
                    return 1;
                }
                setSortField('-description');
                return -1;
            }
        },
        {
            title: 'Timestamp',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text: any) => {
                const date = new Date(text);
                return date.toLocaleString();
            },
            sorter: (a: any, b: any) => {
                if (sortField === 'created_at' || sortField === '-created_at') {
                    setIsAscending(!isAscending);
                }
                if (isAscending) {
                    setSortField('created_at');
                    return 1;
                }
                setSortField('-created_at');
                return -1;
            }
        },
        {
            title: 'Extra Info',
            dataIndex: 'extra',
            key: 'extra',
            render: (extra: any) => {
                if (!extra) return '-';
                return (
                    <ul style={{ paddingLeft: '20px' }}>
                        {Object.entries(extra).map(([key, value]) => (
                            <li key={key}>
                                <strong>{key}:</strong> {value?.toString()}
                            </li>
                        ))}
                    </ul>
                );
            },
            sorter: (a: any, b: any) => {
                if (sortField === 'extra' || sortField === '-extra') {
                    setIsAscending(!isAscending);
                }
                if (isAscending) {
                    setSortField('extra');
                    return 1;
                }
                setSortField('-extra');
                return -1;
            }
        },
    ];

    const getActionHistory = async (page: number, pageSize: number, sortField: string) => {
        try {
            const response = await HistoryService.list(page, pageSize, sortField);
            setDataSource(response.results);
            setTotal(response.count);
        } catch (error: any) {
            console.error(error);
        }
    }

    useEffect(() => {
        getActionHistory(currentPage, pageSize, sortField);
    }, [currentPage, pageSize, sortField]);

    const handlePageChange = async (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPageSize(pageSize);
        getActionHistory(page, pageSize, sortField);
    };

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: total,
                onChange: handlePageChange,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["10", "20", "50"],
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
            }}
            rowKey="id"
            bordered
        />
    );
};

export default ActionHistoryTable;
