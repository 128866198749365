import axios from "axios";

import AuthService from "../../Auth/index";
import { createTypeReferenceDirectiveResolutionCache } from "typescript";

const API_URL = process.env.REACT_APP_API_URL + "/customer/customer-sources";

const list = async (
  currentPage: number,
  pageSize: number,
  keyword: string = "",
  typeFilter: string[] = [],
): Promise<any> => {
  try {
    let query_params = `page=${currentPage}&page_size=${pageSize}`;
    if (keyword) {
      query_params += `&keyword=${keyword}`;
    }
    if (typeFilter.length > 0) {
      query_params += `&types=${typeFilter.join(",")}`;
    }
    const headers: any = await AuthService.getHeader();
    const response = await axios.get(`${API_URL}?${query_params}`, {
      headers: headers,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const getCustomerSourceKeyMetrics = async (keyword: string): Promise<any> => {
  try {
    let query_params = `&keyword=${keyword}`;
    const headers: any = await AuthService.getHeader();
    const response = await axios.get(`${API_URL}/key-metrics?${query_params}`, {
      headers: headers,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

const triggerDownloadAll = async (): Promise<any> => {
  try {
    const headers: any = await AuthService.getHeader();
    const response = await axios.get(`${API_URL}/download-link/generate`, {
      headers: headers,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

const getDownloadLink = async (): Promise<any> => {
  try {
    const headers: any = await AuthService.getHeader();
    const response = await axios.get(`${API_URL}/download-link`, {
      headers: headers,
    });

    return response.data;
  } catch (error: any) {
    throw error.response.data
  }
}

const CustomerSourceService = {
  list,
  getCustomerSourceKeyMetrics,
  triggerDownloadAll,
  getDownloadLink,
};

export default CustomerSourceService;
