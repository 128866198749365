import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Button, Flex, Form, Input, message } from 'antd';
import AuthService from '../../../Services/Auth';


const ProfileForm = ({ profile, isEditable }: {
    profile: any;
    isEditable: boolean;
}) => {
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const [error, setError] = React.useState<string>('');

    const handleUpdateProfile = async (profileId: string, values: any) => {
        try {
            const response = await AuthService.updateProfile(profileId, values);
            if (response) {
                navigate('/user/me');
            }
        } catch (error: any) {
            setError("Invalid input data. Please try again.");
        }
    }

    const onFinish = (values: any) => {
        if (values.password === "") {
            delete values.password;
            console.log('values after delete password', values);
        }

        handleUpdateProfile(profile.id, values);
    }

    const onFinishFailed = (errorInfo: any) => { }

    const onChange = (values: any) => {
        setError('')
    }

    return <Form
        layout="horizontal"
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 6 }}
        disabled={!isEditable}
        style={{ maxWidth: 600 }}
        initialValues={user}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onChange={onChange}
    >
        {
            error && <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
                <div style={{ color: "red" }}>{error}</div>
            </Form.Item>
        }
        <Form.Item label="Email" name="email">
            <Input placeholder='Your email' allowClear />
        </Form.Item>
        <Form.Item label="First name" name="first_name">
            <Input placeholder='Your first name' allowClear />
        </Form.Item>
        <Form.Item label="Last name" name="last_name">
            <Input placeholder='Your last name' allowClear />
        </Form.Item>
        {isEditable && <>
            <Form.Item
                name="password"
                label="New Password"
                rules={[
                    {
                        required: false,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <Input.Password placeholder='Your password' allowClear />
            </Form.Item>
            <Form.Item
                name="password2"
                label="Confirm Password"
                rules={[
                    {
                        required: false,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <Input.Password placeholder='Your password' allowClear />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                    Update
                </Button>
            </Form.Item>
        </>}
    </Form>
};

export default ProfileForm;