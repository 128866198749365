import React from "react";

import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

import FileService from "../../../Services/File";
import { reset, uploadFile } from "../../../Slices/fileSlice";

const { Dragger } = Upload;


const FileDragger: React.FC = () => {
  const dispatch = useDispatch();

  const handleCustomRequest = async (options: any) => {
    dispatch(reset())

    try {
      const uploadedFile = await FileService.upload(options.file)
      dispatch(uploadFile({ 
        id: uploadedFile.id, 
        name: uploadedFile.name,
        tag: uploadedFile.tag,
      }))
      options.onSuccess(uploadedFile, options.file);
    } catch (error: any) {
      options.onError(error);
    }
  };

  return (
    <div className="file-file-dragger">
      <Dragger 
        name="file"
        multiple={false}
        maxCount={1}
        customRequest={handleCustomRequest}
      >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-text">Only support for CSV, or XLSX file</p>
        </Dragger>
    </div>
  );
}

export default FileDragger;