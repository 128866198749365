import React from "react";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Card, Flex, Statistic } from "antd";
import { red, green, yellow, blue, gray } from '@ant-design/colors';


const AnalyticsKeyMetricsTable: React.FC<any> = ({ 
  keyMetrics = {},
  style = {} 
}: {
  keyMetrics?: any,
  style?: any
}) => {
  return (
    <Flex
      className="analytics-key-metrics-table"
      justify="space-between"
      align="center"
      gap="small"
      style={style}
    >
      <Card className="unique-statistic-card" style={{ width: "200px"}}>
        <Statistic
          title="Unique"
          value={typeof keyMetrics.unique === "number" ? keyMetrics.unique : 0}
          valueStyle={{ color: green[5] }}
        />
      </Card>
      <Card className="empty-statistic-card" style={{ width: "200px"}}>
        <Statistic
          title="Empty"
          value={typeof keyMetrics.empty === "number" ? keyMetrics.empty : 0}
          valueStyle={{ color: gray[5] }}
        />
      </Card>
      <Card className="invalid-statistic-card" style={{ width: "200px"}}>
        <Statistic
          title="Invalid"
          value={typeof keyMetrics.invalid === "number" ? keyMetrics.invalid : 0}
          valueStyle={{ color: red[5] }}
        />
      </Card>
      <Card className="duplicated-in-file-statistic-card" style={{ width: "200px"}}>
        <Statistic
          title="Duplicated in File"
          value={typeof keyMetrics.duplicated_in_file === "number" ? keyMetrics.duplicated_in_file : 0}
          valueStyle={{ color: yellow[5] }}
        />
      </Card>
      <Card className="duplicated-in-database-statistic-card" style={{ width: "200px"}}>
        <Statistic
          title="Duplicated in Database"
          value={typeof keyMetrics.duplicated_in_database === "number" ? keyMetrics.duplicated_in_database : 0}
          valueStyle={{ color: blue[5] }}
        />
      </Card>
    </Flex>
  );
};

export default AnalyticsKeyMetricsTable;
