import React from 'react';
import { Select } from 'antd';
import type { SelectProps } from 'antd';

const defaultOptions = [
  { value: "unique", label: "Unique" },
  { value: "invalid", label: "Invalid" },
  { value: "empty", label: "Empty" },
  { value: "duplicated_in_database", label: "Duplicate in Database" },
  { value: "duplicated_in_file", label: "Duplicate in File" },
]


const TypeSelector: React.FC<any> = ({
  onChange = null,
  options = defaultOptions,
  style = { width: '100%' },
}: {
  onChange?: any,
  options?: SelectProps['options'],
  style?: any,
}) => {
  return (
    <Select
      maxTagCount={1}
      mode="multiple"
      style={style}
      options={options}
      placeholder="Select type"
      onChange={onChange}
    />
  );
}

export default TypeSelector;