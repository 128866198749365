import React, { useEffect } from "react";

import { Flex } from "antd";

import ActionHistoryTable from "../../../Components/History/ActionHistoryTable";


const UserActionHistoriesListing = () => {
    return (
        <Flex gap="small" vertical>
            <h1>Action Histories</h1>
            <ActionHistoryTable />
        </Flex>
    )
};

export default UserActionHistoriesListing;