import axios from "axios";
import AuthService from "../../Services/Auth";

const HISTORY_API_URL = process.env.REACT_APP_API_URL + '/history';

const list = async (page: number, pageSize: number, orderBy: string) => {
  try {
    const response = await axios.get(
      HISTORY_API_URL + `/action-histories/?page=${page}&page_size=${pageSize}&ordering=${orderBy}`,
      {
        headers: await AuthService.getHeader()
      }
    )
    return response.data;
  } catch (error: any) {
    throw error;
  }
}

const HistoryService = {
  list,
};

export default HistoryService;
