import React, { useEffect, useState } from "react";

import { Flex, message, Table, Tag } from "antd";

import { CustomerSourceService } from "../../../Services/Customer";

const CustomerSourceTable = ({
  scrollHeight = "240px",
  searchText = "",
  typeFilter = [],
  style = {},
}: {
  typeFilter?: string[];
  scrollHeight?: string;
  searchText?: string;
  style?: any;
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const columns = [
    {
      title: <Flex justify="center">Name</Flex>,
      dataIndex: "full_name",
      key: "name",
      width: "12%",
      render: (text: string) => <Flex justify="center">{text}</Flex>,
    },
    {
      title: <Flex justify="center">Phone</Flex>,
      dataIndex: "phone",
      key: "phone",
      width: "10%",
      render: (text: string) => <Flex justify="center">{text}</Flex>,
    },
    {
      title: <Flex justify="center">Type</Flex>,
      dataIndex: "type",
      key: "type",
      width: "15%",
      render: (type: string) => {
        let color;
        switch (type) {
          case "unique":
            color = "green";
            break;
          case "invalid":
            color = "red";
            break;
          case "duplicated_in_file":
            color = "yellow";
            break;
          case "duplicated_in_database":
            color = "blue";
            break;
          default:
            color = "gray";
            break;
        }
        return (
          <Flex justify="center">
            <Tag color={color} key={type}>
              {type.toUpperCase()}
            </Tag>
          </Flex>
        );
      },
    },
    {
      title: <Flex justify="center">File ID</Flex>,
      dataIndex: "file",
      key: "file_id",
      width: "6%",
      render: (file: any) => <Flex justify="center">{file.id}</Flex>,
    },
    {
      title: <Flex justify="center">File name</Flex>,
      dataIndex: "file",
      key: "file_name",
      width: "15%",
      render: (file: any) => <Flex justify="center">{file.name}</Flex>,
    },
  ];

  const handleListCustomerSource = async (
    page: number,
    pageSize: number,
    keyword: string = "",
    typeFilter: string[] = []
  ) => {
    try {
      const response = await CustomerSourceService.list(
        page,
        pageSize,
        keyword,
        typeFilter
      );
      setDataSource(response.data);
      setTotal(response.total);
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const handlePageChange = async (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    handleListCustomerSource(currentPage, pageSize, searchText, typeFilter);
  }, [searchText, currentPage, pageSize, typeFilter]);

  return (
    <div
      className="customer-source-listing-table"
      style={style}
    >
      <Table
        columns={columns}
        dataSource={dataSource}
        scroll={{
          y: scrollHeight,
          x: "calc(100vw - 200px)",
        }}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total) => `Total ${total} items`,
          onChange: handlePageChange,
          onShowSizeChange: handlePageChange,
        }}
        rowKey="id"
      />
    </div>
  );
};

export default CustomerSourceTable;
