import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Flex, message, Table, Tag, Tooltip } from "antd";

import FileService from "../../../Services/File";
import { setStatus } from "../../../Slices/fileSlice";


const FileDataTable: React.FC<any> = ({
  file = {},
  searchText = "",
  scrollHeight = "240px",
  scrollWidth = "calc(100vw - 200px)",
  typesFilter = [],
  style = {} || { marginTop: "20px" },
  excludeFields = [],
}: {
  file: any,
  searchText?: string;
  scrollHeight?: string;
  scrollWidth?: string;
  typesFilter?: string[];
  style?: any;
  excludeFields?: string[];
}) => {
  file = useSelector((state: any) => state.file || file);
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dataSource, setDataSource] = useState([]);
  var columns = [
    {
      title: <Flex justify="center">Name</Flex>,
      dataIndex: "full_name",
      key: "name",
      width: "12%",
    },
    {
      title: <Flex justify="center">Phone</Flex>,
      dataIndex: "phone",
      key: "phone",
      width: "10%",
      render: (text: string) => {
        return (
          <Tooltip placement="top" title="View files belongs">
            <Link to={`/customers/customer-sources?keyword=${text}`}>{text}</Link>
          </Tooltip>
        );
      }
    },
    {
      title: <Flex justify="center">Type</Flex>,
      dataIndex: "type",
      key: "type",
      width: "15%",
      render: (type: string) => {
        let color;
        switch (type) {
          case "unique":
            color = "green";
            break;
          case "invalid":
            color = "red";
            break;
          case "duplicated_in_file":
            color = "yellow";
            break;
          case "duplicated_in_database":
            color = "blue";
            break;
          default:
            color = "gray";
            break;
        }
        return (
          <Tag color={color} key={type}>
            {type.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: <Flex justify="center">Location</Flex>,
      dataIndex: "location",
      key: "location",
      width: "25%",
    },
    {
      title: <Flex justify="center">Product</Flex>,
      dataIndex: "product",
      key: "product",
    },
    {
      title: <Flex justify="center">Order date</Flex>,
      dataIndex: "order_date",
      key: "order_date",
      width: "10%",
    },
    {
      title: <Flex justify="center">Note</Flex>,
      dataIndex: "note",
      key: "note",
    },
    {
      title: <Flex justify="center">Duplicated in File (if any)</Flex>,
      dataIndex: "duplicated_file",
      key: "duplicated_file",
      render: (file: any) => {
        return (file ? file.name : null);
      }
    },
  ];

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    getProcessedData(file, page, pageSize);
  };

  const checkFileStatus = async (file: any) => {
    try {
      const response = await FileService.getFile(file.id);
      if (response) {
        dispatch(setStatus({status: response.status}));
      }
    } catch (error: any) {
      message.error("Failed to check file's status: " + error.message);
    }
  }

  const getProcessedData = async (
    file: any,
    currentPage: number,
    pageSize: number,
    keyword: string = '',
    typesFilter: string[] = [],
  ) => {
    if (!file) return;
    try {
      const response = await FileService.getProcessedData(
        file.id,
        currentPage,
        pageSize,
        keyword,
        typesFilter,
      );
      if (response.data) {
        setDataSource(response.data);
        setTotal(response.total);
      }
    } catch (error: any) {
      message.error("Failed to fetch data: " + error.message);
    }
  };

  useEffect(() => {
    if (file.id) {
      if (FileService.isCanDownload(file)) {
        getProcessedData(file, currentPage, pageSize, searchText, typesFilter);
      } else if (FileService.isProcessing(file)) {
        const intervalId = setInterval(checkFileStatus, 2000, file);
        return () => clearInterval(intervalId);
      }
    } else {
      setCurrentPage(1);
      setPageSize(10);
      setDataSource([]);
      setTotal(0);
    }
  }, [file, searchText, typesFilter])

  if (excludeFields.length > 0) {
    columns = columns.filter(
      (column) => !excludeFields.includes(column.dataIndex)
    );
  }

  return (
    <div className="file-data-table" style={style}>
      <Table
        bordered
        dataSource={dataSource}
        columns={columns}
        scroll={{
          y: scrollHeight,
          x: scrollWidth,
        }}
        loading={FileService.isProcessing(file)}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: handlePageChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["10", "20", "50"],
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
        }}
      />
    </div>
  );
};

export default FileDataTable;
