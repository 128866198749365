import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Outlet, Navigate } from "react-router-dom";

import { Button, Dropdown, Flex, Layout, Space, theme } from "antd";
import type { MenuProps } from "antd";
import { UserOutlined } from "@ant-design/icons";

import MenuSider from "../../Components/Common/MenuSider";
import { useAuth } from "../../Hooks";
import AuthService from "../../Services/Auth";
import { setUser } from "../../Slices/userSlice";

const { Header, Content } = Layout;
const mainLayoutStyle = {
  minHeight: "calc(100vh)",
};
const contentStyle = {
  // maxHeight: "calc(100vh - 112px)",
};

const MultiContentLayout: React.FC<any> = () => {
  useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state: any) => state.auth);
  const user: any = useSelector((state: any) => state.user);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleMenuOnClick = async (e: any) => {
    switch (e.key) {
      case "me":
        navigate("/user/me");
        break;
      case "register":
        navigate("/auth/register");
        break;
      case "logout":
        navigate("/auth/logout");
        break;
      default:
        break;
    }
  };

  const handleGetMe = async () => {
    try {
      const response = await AuthService.me();
      console.log(response);
      if (response) {
        dispatch(
          setUser({
            id: response.id,
            first_name: response.first_name,
            last_name: response.last_name,
            email: response.email,
            username: response.username,
            isSuperuser: response.is_superuser,
            isStaff: response.is_staff,
          })
        );
      }
    } catch (error: any) {
      // do nothing
    }
  };

  const items: MenuProps["items"] = (
    user.isSuperuser
    ? [
        // {
        //   label: "Profile",
        //   key: "me",
        // },
        {
          label: "Create a new account",
          key: "register",
        },
        {
          label: "Logout",
          key: "logout",
        },
      ]
    : [
        // {
        //   label: "Profile",
        //   key: "me",
        // },
        {
          label: "Logout",
          key: "logout",
        },
      ]
  );
  const menuProps = {
    items,
    onClick: handleMenuOnClick,
  };

  useEffect(() => {
    if (isLoggedIn) {
      handleGetMe();
    }
  });

  return (
    <Layout style={mainLayoutStyle}>
      <MenuSider />
      <Layout>
        <Header
          style={{
            padding: "10px",
            background: colorBgContainer,
            borderRadius: "0px",
            borderColor: "transparent",
          }}
        >
          <Flex className="header-content" justify="flex-end" align="center">
            {isLoggedIn ? (
              <Flex
                className="user-profile-container"
                justify="space-between"
                align="center"
                gap="small"
              >
                <Flex
                  className="user-name"
                  justify="center"
                  align="center"
                  style={{ height: "40px" }}
                >
                  {typeof user.email === "string" ? (
                    <h3>{user.email}</h3>
                  ) : null}
                </Flex>
                <Dropdown className="user-settings" menu={menuProps}>
                  <Button type="default" size="large" shape="circle">
                    <Space>
                      <UserOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Flex>
            ) : (
              <Flex
                className="user-anonymous"
                justify="center"
                align="center"
                gap="small"
                style={{ height: "40px" }}
              >
                <Button onClick={() => navigate("/auth/register")}>
                  Register
                </Button>
                <Button onClick={() => navigate("/auth/login")}>Login</Button>
              </Flex>
            )}
          </Flex>
        </Header>
        <Content style={contentStyle}>
          <div
            style={{
              // background: colorBgContainer,
              borderRadius: borderRadiusLG,
              margin: "24px 16px 0",
              minHeight: "calc(100vh - 112px)",
              padding: 24,
              overflowY: "auto",
            }}
          >
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MultiContentLayout;
