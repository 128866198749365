import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import { Button, Flex, message } from "antd";

import {
  AnalyticsContainer,
  AnalyticsKeyMetricsTable,
} from "../../../Components/Analytics";
import { ToolsBar } from "../../../Components/Common";
import { ProfileForm } from "../../../Components/User";
import AuthService from "../../../Services/Auth";

const ProfileDetailPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state: any) => state.auth);
  const [profile, setProfile] = React.useState<any>({});

  const getUserProfile = async () => {
    try {
      const response = await AuthService.me();
      if (response) {
        setProfile(response);
      }
    } catch (error: any) {
      message.error(error.message);
    }
  }

  useEffect(() => {
    getUserProfile();
  }, []);

  if (!isLoggedIn) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <div className="user-me-page">
      <Flex justify="center" gap="middle" vertical>
        <ToolsBar
          rightChildren={
            <Flex gap="small">
              <Button type="primary" onClick={
                () => navigate('/user/update')
              }>Edit Profile</Button>
            </Flex>
          }
        />
        <ProfileForm profile={profile} isEditable={false} />
      </Flex>
    </div>
  );
};

export default ProfileDetailPage;
