import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { Button, Flex, message, Space, Progress } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import {
  AnalyticsContainer,
  AnalyticsKeyMetricsTable,
} from "../../../Components/Analytics";
import {
  SearchInput,
  ToolsBar,
  TypeSelector,
} from "../../../Components/Common";
import { CustomerSourceTable } from "../../../Components/Customer";
import { CustomerSourceService } from "../../../Services/Customer";

const CustomerSourcesByPhonePage: React.FC = () => {
  const { isLoggedIn } = useSelector((state: any) => state.auth);
  const user = useSelector((state: any) => state.user);
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);
  const [loadingDownloadButton, setLoadingDownloadButton] = useState(false);
  const [downloadInterval, setDownloadInterval] = useState<NodeJS.Timeout | null>(null);
  const [progress, setProgress] = useState(0);
  const [searchText, setSearchText] = useState(
    queryParameters.get("keyword") || ""
  );
  const [typeFilter, setTypeFilter] = useState([] as string[]);
  const [keyMetrics, setKeyMetrics] = useState({
    unique: 0,
    empty: 0,
    invalid: 0,
    duplicated_in_file: 0,
    duplicated_in_database: 0,
  })

  const handleGetCustomerSourceKeyMetrics = async () => {
    try {
      setLoading(true);
      const response = await CustomerSourceService.getCustomerSourceKeyMetrics(searchText);
      setKeyMetrics(response);
    } catch (error: any) {
      message.error(error);
      message.error("Failed to get key metrics");
    } finally {
      setLoading(false);
    }
  }

  const handleTriggerToGenerateDownloadLink = async () => {
    try {
      setLoadingDownloadButton(true);
      await CustomerSourceService.triggerDownloadAll();
    } catch (error: any) {
      message.error("Failed to generate all customer sources file");
    }
  }

  const handleGetDownloadAllCustomerSourceFile = async () => {
    try {
      const response = await CustomerSourceService.getDownloadLink();
      console.log(response);
      if (response.download_url) {
        console.log(response.download_url);
        response.download_url.forEach((url: string) => {
          console.log(typeof url, url);
          try {
            message.success("Download link is ready");
            window.open(url, '_blank');
          } catch (error: any) {}
          if (downloadInterval) {
            clearInterval(downloadInterval);
          }
          setLoadingDownloadButton(false);
        });
      }
    } catch (error: any) {
      if (error.error === "The download link has not been ready yet") {
        return;
      }
      message.error("Failed to get download link");
    }
  }

  useEffect(() => {
    handleGetCustomerSourceKeyMetrics();
  }, [searchText])

  useEffect(() => {
    if (loadingDownloadButton) {
      handleGetDownloadAllCustomerSourceFile();
      const interval = setInterval(handleGetDownloadAllCustomerSourceFile, 5000);
      setDownloadInterval(interval);

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
  }, [loadingDownloadButton])

  if (!isLoggedIn) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <div className="customer-source-listing-page">
      <Flex justify="center" gap="middle" vertical>
        <AnalyticsContainer
          children={
            <AnalyticsKeyMetricsTable
              keyMetrics={keyMetrics}
            />
          }
        />
        <ToolsBar
          leftChildren={
            <>
              <SearchInput
                inputProps={{
                  disabled: (user.isSuperuser ? false : true),
                  placeholder: "Search by phone",
                  onChange: (e: any) => {
                    setSearchText(e.target.value);
                  },
                  style: { width: "20%" },
                  defaultValue: searchText,
                }}
              />
              <TypeSelector
                style={{
                  width: "20%",
                }}
                onChange={(value: string[]) => {
                  setTypeFilter(value);
                }}
              />
            </>
          }
          rightChildren={
            loadingDownloadButton ?
              <Button type="primary" loading>
                <Space>
                  Download
                  <DownloadOutlined />
                </Space>
              </Button> :
              <Button type="primary" onClick={handleTriggerToGenerateDownloadLink}>
                <Space>
                  Download
                  <DownloadOutlined />
                </Space>
              </Button>
          }
        />
        {loading && <Progress percent={progress} />}
        <CustomerSourceTable
          scrollHeight="480px"
          searchText={searchText}
          typeFilter={typeFilter}
        />
      </Flex>
    </div >
  );
};

export default CustomerSourcesByPhonePage;
