import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Flex, message, Table, Tag, Tooltip } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  PullRequestOutlined,
} from "@ant-design/icons";

import FileService from "../../../Services/File";

const FilesTable = ({
  searchText = "",
  scrollHeight = "400px",
  excludeFields = [],
}: {
  searchText?: string;
  scrollHeight?: string;
  excludeFields?: string[];
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [downloadingFileIDs, setDownloadingFileIDs] = useState<Array<number>>(
    []
  );
  const [sortedBy, setSortedBy] = useState("");

  const handleListFiles = async (
    currentPage: number,
    pageSize: number,
    keyword: string = "",
    sortedBy: string = ""
  ) => {
    try {
      const response = await FileService.listFiles(
        currentPage,
        pageSize,
        keyword,
        sortedBy
      );
      setDataSource(response.data);
      setTotal(response.total);
    } catch (error) {
      console.error("Error listing files:", error);
    }
  };

  const handleSorterChange = (sorter: any) => {
    if (!sorter.columnKey === undefined) {
      let sortedBy = sorter.columnKey;
      if (sorter.columnKey.startsWith("file_")) {
        sortedBy = sorter.columnKey.replace("file_", "");
      }
      if (sorter.order === "descend") {
        sortedBy = "-" + sortedBy;
      }
      setSortedBy(sortedBy);
      handleListFiles(currentPage, pageSize, searchText, sortedBy);
    }
  };

  const handleDownloadFile = async (file: any) => {
    try {
      await setDownloadingFileIDs(downloadingFileIDs.concat(file.id));
      await FileService.download(file.id, "xlsx", file.name);
    } catch (error: any) {
      message.error(error.message);
    } finally {
      await setDownloadingFileIDs(
        downloadingFileIDs.filter((id) => id !== file.id)
      );
    }
  };

  const handleDeleteFile = async (file: any) => {
    try {
      FileService.deleteFile(file.id);
      handlePageChange(currentPage, pageSize);
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const handleViewFileDetail = async (file: any) => {
    navigate(`/files/detail/${file.id}`);
  };

  const handleMergeFile = async (file: any) => {
    navigate(`/files/merge/${file.id}`);
  };

  const handlePageChange = async (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    handleListFiles(page, pageSize, searchText);
  };

  var keyMetricColumns = [
    {
      title: <Flex justify="center">ID</Flex>,
      dataIndex: "file",
      key: "file_id",
      width: "6%",
      sorter: true,
      render: (file: any) => {
        return <div style={{ textAlign: "center" }}>{file.id}</div>;
      },
    },
    {
      title: <Flex justify="center">Name</Flex>,
      dataIndex: "file",
      key: "file_name",
      render: (file: any) => {
        return <div style={{ textAlign: "center" }}>{file.name}</div>;
      },
    },
    {
      title: <Flex justify="center">Unique</Flex>,
      dataIndex: "total_unique",
      key: "total_unique",
      width: "8%",
      sorter: true,
      render: (text: string) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Tag color={"green"}>{text}</Tag>
          </div>
        );
      },
    },
    {
      title: <Flex justify="center">Empty</Flex>,
      dataIndex: "total_empty",
      key: "total_empty",
      width: "8%",
      sorter: true,
      render: (text: string) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Tag color={"gray"}>{text}</Tag>
          </div>
        );
      },
    },
    {
      title: <Flex justify="center">Invalid</Flex>,
      dataIndex: "total_invalid",
      key: "total_invalid",
      width: "8%",
      sorter: true,
      render: (text: string) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Tag color={"red"}>{text}</Tag>
          </div>
        );
      },
    },
    {
      title: <Flex justify="center">Duplicated in file</Flex>,
      dataIndex: "total_duplicated_in_file",
      key: "total_duplicated_in_file",
      width: "10%",
      sorter: true,
      render: (text: string) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Tag color={"yellow"}>{text}</Tag>
          </div>
        );
      },
    },
    {
      title: <Flex justify="center">Duplicated in database</Flex>,
      dataIndex: "total_duplicated_in_database",
      key: "total_duplicated_in_database",
      width: "10%",
      sorter: true,
      render: (text: string) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Tag color={"blue"}>{text}</Tag>
          </div>
        );
      },
    },
    {
      title: <Flex justify="center">Updated at</Flex>,
      dataIndex: "file",
      key: "file_updated_at",
      width: "10%",
      sorter: true,
      render: (file: any) => {
        const dateObject = new Date(file.updated_at);
        return dateObject.toLocaleDateString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
      },
    },
    {
      title: <Flex justify="center">Actions</Flex>,
      dataIndex: "file",
      key: "action",
      render: (event: any, record: any) => {
        return (
          <>
            <Flex gap="small" justify="center" wrap>
              <Tooltip placement="top" title="Download file">
                <Button
                  icon={<DownloadOutlined />}
                  size="small"
                  loading={downloadingFileIDs.includes(record.file.id)}
                  onClick={() => {
                    const file = record.file;
                    handleDownloadFile(file);
                  }}
                />
              </Tooltip>
              {/* <Tooltip placement="top" title="Merge file">
                <Button
                  icon={<PullRequestOutlined />}
                  size="small"
                  onClick={() => {
                    const file = record.file;
                    handleMergeFile(file);
                  }}
                />
              </Tooltip> */}
              <Tooltip placement="top" title="View file">
                <Button
                  icon={<EyeOutlined />}
                  size="small"
                  onClick={() => {
                    const file = record.file;
                    handleViewFileDetail(file);
                  }}
                />
              </Tooltip>
              <Tooltip placement="top" title="Delete file">
                <Button
                  icon={<DeleteOutlined />}
                  size="small"
                  onClick={() => {
                    const file = record.file;
                    handleDeleteFile(file);
                  }}
                />
              </Tooltip>
            </Flex>
          </>
        );
      },
    },
  ];

  if (excludeFields.length > 0) {
    keyMetricColumns = keyMetricColumns.filter(
      (column) => !excludeFields.includes(column.key)
    );
  }

  useEffect(() => {
    setCurrentPage(1);
    handleListFiles(1, pageSize, searchText);
  }, [searchText]);

  return (
    <div className="file-data-table" style={{ marginTop: "20px" }}>
      <Table
        bordered
        dataSource={dataSource}
        columns={keyMetricColumns}
        onChange={(pagination, filters, sorter) => {
          handleSorterChange(sorter);
        }}
        scroll={{
          y: scrollHeight,
        }}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: handlePageChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["10", "20", "50"],
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
        }}
      />
    </div>
  );
};

export default FilesTable;
